(function(app) {
    'use strict';

    app.component('frtActivityGroup', {
        templateUrl: '/js/components/frtActivityGroup/activity-group.html',
        bindings: {
            activity: '=',
            task: '=',
            point: '=',
            activityType: '='
        },
        controllerAs: 'vm',
        controller: function() {
            var vm = this;
        }
    });

})(angular.module('app'));
